import React, { useEffect, useState } from 'react';
import styles from './Login.module.css';
import { Person, Lock, Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { REACT_APP_BASE_URL as baseUrl } from '../../config/keys';
import { ClipLoader } from 'react-spinners';
import { IconButton } from '@mui/material';
import axios from 'axios';
import { ProductState } from '../../context/ProductProvider';
import OneSignalReact from 'react-onesignal';
const Login = ({ docTitle }) => {
  const navigate = useNavigate();
  const [number, setNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const userData = JSON.parse(localStorage.getItem('@userData'));
  const { refresh, setRefresh } = ProductState();
 

  useEffect(() => {
    if (userData) {
      navigate('/tracking');
    }
  }, []);
  useEffect(() => {
    document.title = docTitle;
  }, []);

  const signIn = async (e) => {
    e.preventDefault();
    try {
      if (number && password) {
        setLoading(true);
        const result = await axios.post(`${baseUrl}/user/signIn`, {
          data: number,
          password,
        });
        setNumber('');
        setPassword('');
        // console.log('result line 37 login->', result);
        if (result.data.status === '200') {
          if (result.data.data.type === 'admin') {
            localStorage.setItem(
              '@userData',
              JSON.stringify({
                data: result.data.data,
              })
            );
            navigate('/tracking');
            setRefresh(!refresh);
          } else {
            alert('You are not authorized to login');
          }
        } else {
          alert(`${result.data.msg}\n Enter Correct Password and Number`);
        }
        setLoading(false);
      } else {
        alert('Please Fill all the fields');
      }
    } catch (e) {
      console.log(e);
    }
  };
  const passwordVisible = () => {
    if (!showPassword) {
      return 'text';
    } else {
      return 'password';
    }
  };
  if (loading) {
    return (
      <div
        style={{
          height: '100vh',
          width: '100vw',
          display: 'grid',
          placeContent: 'center',
        }}
      >
        <ClipLoader color="#ff7378" size={40} />
      </div>
    );
  }

  return (
    <div className={styles.login}>
      <div className={styles.login__container}>
        <div className={styles.login__circles}>
          <div className={styles.login__circleContainer}>
            <div className={styles.circle__1} />
            <div className={styles.circle__2} />
          </div>
          <div className={styles.circle__curve} />
        </div>

        <div className={styles.login__authContainer}>
          <img
            src={require('../../assets/dastak.png')}
            alt=""
            className={styles.image}
          />
          <h3>Login To Continue</h3>
          <form action="">
            <div className={styles.input}>
              <input
                type="number"
                placeholder="Enter Number"
                value={number}
                onChange={(text) => setNumber(text.target.value)}
              />
              <div className={styles.icon}>
                <Person />
              </div>
            </div>

            <div className={styles.input}>
              <input
                onChange={(text) => setPassword(text.target.value)}
                type={passwordVisible()}
                name=""
                id=""
                placeholder="Enter Password"
              />
              <div className={styles.eyeButton} id={styles.eye}>
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {!showPassword ? (
                    <Visibility sx={{ color: 'black', fontSize: '20px' }} />
                  ) : (
                    <VisibilityOff sx={{ color: 'black', fontSize: '20px' }} />
                  )}
                </IconButton>
              </div>

              <div className={styles.icon}>
                <Lock />
              </div>
            </div>
          </form>

          <button type="submit" onClick={signIn} className={styles.LoginButton}>
            Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
