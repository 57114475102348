/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react'
import Products from '../../components/products/Products'
import styles from './MenuScreen.module.css'
import SideBar from '../../components/sideBar/SideBar';
import axios from 'axios';
import { REACT_APP_BASE_URL as baseUrl } from '../../config/keys';
import { ProductState } from '../../context/ProductProvider';
import { useNavigate } from 'react-router-dom';
const MenuScreen = ({docTitle}) => {
  const mart=JSON.parse(localStorage.getItem('@userData'))
   const [productsData,setProductsData]=useState([])
   const {refresh}=ProductState()
   const navigate=useNavigate()
  //  useEffect(()=>{
  //   if(!mart){
  //    navigate('/login')
  //   }
  //  },[])
   useEffect(()=>{
   (async()=>{
    try{
      const result =await axios.post(`${baseUrl}/products/allRestaurantProducts`,
      {
        martId:mart?.data?._id,
        type:'admin'
      })
      // console.log('result',result)
      if(result.data.status==='200'){
        setProductsData(result.data)
      }else{
        alert('')
      }
      
    }catch(e){
    console.log(e)
    }
   })()
   },[refresh]) 



    useEffect(()=>{
    document.title=docTitle
    },[])
  return (
    <div className={styles.menuScreen}>
      
    <SideBar/>  
    <div className={styles.menuScreen_products}>
    <Products  restaurantName={mart?.data?.name}  products={productsData} />
    </div>
    
    </div>
   
  )
}

export default MenuScreen